import React from "react";
import { Link } from "gatsby";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Logo from "~/images/logo.svg";
import LinkButton from "./linkButton";

function Footer() {
  return (
    <footer>
      <Container>
        <Row>
          <Col>
            <div className="footer-content">
              <Link to="/">
                <img
                  src={Logo}
                  className="logo"
                  alt="New Energy Academy logo"
                />
              </Link>
              <div className="footer-nav">
                <a href="" className="no-underline">
                  Sign in
                </a>
                <LinkButton to="/pathways/" variant="primary" size="sm">
                  Start
                </LinkButton>
              </div>
            </div>
            <div className="copyright grey-text">
              © New Energy Academy. {new Date().getFullYear()}. All rights
              reserved.
            </div>
          </Col>
        </Row>
      </Container>
    </footer>
  );
}

export default Footer;
