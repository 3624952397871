import React, { useState } from "react";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import Col from "react-bootstrap/Col";
import { Course } from "~/model";
import formatPrice from "~/utils/formatPrice";

interface HomeCourseCardProps {
  readonly course: Course;
}

const truncateLength = 125;

function HomeCourseCard({ course }: HomeCourseCardProps) {
  const imageData = getImage(course.image.childImageSharp.gatsbyImageData);
  const [isExpanded, setExpanded] = useState(false);
  const shouldUseTruncate = course.summary.length > truncateLength;

  return (
    <Col key={course.id} xs={12} sm={6} md={4} className="d-flex">
      <div className="course-card d-flex flex-column">
        <div className="course-card-img-wrapper">
          {imageData && (
            <GatsbyImage image={imageData} alt="" className="course-card-img" />
          )}
        </div>
        <div className="course-card-content d-flex flex-column h-100">
          <h3>{course.name}</h3>
          <div className="d-flex flexible">
            <p className="grey-text">
              {shouldUseTruncate ? (
                isExpanded ? (
                  <>
                    <span>{course.summary}</span>
                    <button
                      type="button"
                      onClick={() => setExpanded(false)}
                      className="btn btn-link"
                    >
                      Less
                    </button>
                  </>
                ) : (
                  <>
                    <span>{course.summary.slice(0, truncateLength)}...</span>
                    <button
                      type="button"
                      onClick={() => setExpanded(true)}
                      className="btn btn-link"
                    >
                      More
                    </button>
                  </>
                )
              ) : (
                <span>{course.summary}</span>
              )}
            </p>
          </div>
          <span className="price">{formatPrice(course.price)}</span>
        </div>
      </div>
    </Col>
  );
}

export default HomeCourseCard;
