import React, { useState } from "react";
import { graphql } from "gatsby";
import classNames from "classnames";
import { StaticImage } from "gatsby-plugin-image";
import scrollTo from "gatsby-plugin-smoothscroll";
import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Layout from "~/components/layout";
import Footer from "~/components/footer";
import Seo from "~/components/seo";
import LinkButton from "~/components/linkButton";
import HomeCourseCard from "~/components/homeCourseCard";
import ellipseHalf from "~/images/ellipse-half.svg";
import dots from "~/images/dots.svg";
import ellipse from "~/images/ellipse.svg";
import openSolar from "~/images/openSolar.png";
import nen from "~/images/NEX_LOGO_Final.svg";
import gses from "~/images/GSES-logo-rev.svg";
import { PageProps } from "~/gatsbyPages";
import { Course, Program, RelayConnection } from "~/model";

type IndexPageProps = PageProps<{
  readonly allOpenEdxContentCourse: RelayConnection<Course>;
  readonly allOpenEdxContentProgram: RelayConnection<Program>;
}>;

function IndexPage({
  data: { allOpenEdxContentCourse, allOpenEdxContentProgram },
}: IndexPageProps) {
  const [selectedProgramIndex, setSelectedProgramIndex] = useState(0);
  const allPrograms = allOpenEdxContentProgram.edges.map(({ node }) => node);
  const programCourses = allOpenEdxContentCourse.edges
    .map(({ node }) => node)
    .filter((node) => node.programId === allPrograms[selectedProgramIndex]?.id);

  return (
    <Layout>
      <Seo
        title="Accelerate your career in solar"
        description="New Energy Academy provides online learning for the solar energy industry. Reach your solar energy career goals."
      />
      <section role="main">
        <Container>
          <Row>
            <Col>
              <div className="hero">
                <div className="hero-left">
                  <div className="hero-content">
                    <div className="ellipse-half">
                      <img
                        src={ellipseHalf}
                        alt="half circle for decoration"
                        aria-label="hidden"
                      />
                    </div>
                    <h1>Accelerate your career in solar</h1>
                    <p className="lead grey-text">
                      Find the right courses, and start learning, to reach your
                      solar energy career goals.
                    </p>
                    <div className="btn-row">
                      <LinkButton to="/pathways/" variant="primary" size="lg">
                        Get Started
                      </LinkButton>
                      <Button
                        onClick={() => scrollTo("#courses")}
                        variant="link"
                        size="lg"
                      >
                        View all courses
                      </Button>
                    </div>
                  </div>
                </div>
                <div className="hero-right">
                  <StaticImage
                    src="../images/solar-panels.png"
                    backgroundColor="#25314B"
                    layout="fullWidth"
                    quality={100}
                    formats={["auto", "webp", "avif"]}
                    alt="Man with laptop working in large solar panel installation"
                    className="hero-img"
                  />
                  <div className="dots">
                    <img
                      src={dots}
                      alt="dots for decoration"
                      aria-label="hidden"
                    />
                  </div>
                  <div className="ellipse">
                    <img
                      src={ellipse}
                      alt="circle for decoration"
                      aria-label="hidden"
                    />
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>

      <section className="logo-section">
        <Container>
          <Row>
            <p>
              The New Energy Academy online training platform is a
              capacity-building collaboration between:
            </p>
            <div className="logo-row">
              <div className="a-logo">
                <img src={openSolar} alt="OpenSolar logo" />
              </div>
              <div className="a-logo">
                <img src={nen} alt="New Energy Nexus logo" />
              </div>
              <div className="a-logo">
                <img src={gses} alt="GSES logo" />
              </div>
            </div>
          </Row>
        </Container>
      </section>

      <section className="section-pad" id="courses">
        <Container>
          <Row>
            <Col>
              <h2>Courses</h2>

              <div className="cta">
                <div className="cta-left">
                  <div className="ellipse-half-sm">
                    <img
                      src={ellipseHalf}
                      alt="half circle for decoration"
                      aria-label="hidden"
                    />
                  </div>
                  <p>
                    Generate a course list to match your current experience and
                    career goals
                  </p>
                </div>
                <LinkButton to="/pathways/" variant="primary" size="lg">
                  Get Started
                </LinkButton>
              </div>

              <div className="course-filters">
                {allPrograms.map((program, i) => (
                  <Button
                    key={program.id}
                    variant="outline-primary"
                    size="sm"
                    className={classNames({
                      "not-selected": selectedProgramIndex !== i,
                    })}
                    onClick={() => setSelectedProgramIndex(i)}
                  >
                    {program.name}
                  </Button>
                ))}
              </div>
            </Col>
          </Row>

          <Row>
            {programCourses.map((course) => (
              <HomeCourseCard key={course.id} course={course} />
            ))}
          </Row>
        </Container>
      </section>

      <Footer />
    </Layout>
  );
}

const query = graphql`
  query IndexQuery {
    allOpenEdxContentProgram {
      edges {
        node {
          __typename
          id
          name
          ordering
        }
      }
    }
    allOpenEdxContentCourse {
      edges {
        node {
          __typename
          id
          name
          price {
            aud
            php
          }
          programId
          description
          summary
          ordering
          image {
            id
            childImageSharp {
              gatsbyImageData(
                backgroundColor: "#25314B"
                layout: FULL_WIDTH
                quality: 100
                aspectRatio: 1.57142857
              )
            }
          }
        }
      }
    }
  }
`;

export { query };
export default IndexPage;
